<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color' : ''"
			@input="handleInput($event)"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true"
		>
			<span slot="noOptions">
				{{ $t("no_options") }}
			</span>
			<span slot="noResult">
                {{ $t("no_result") }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError"
		/>
	</div>
</template>

<script>
	// Helpers
	import translateNSelected from "@/helpers/translateNSelected";
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";
	
	// Services
	import ThesisAndProjectService from "@/services/ThesisAndProjectService";
	
	// Other
	import qs from "qs";
	
	export default {
		props: {
			type: {
				type: String,
				default: 'thesis',
			},
			value: {
				default: null,
			},
			validateError: {
				type: String,
				default: "",
			},
		},
		data() {
			return {
				selected: null,
				options: []
			}
		},
		watch: {
			value: {
				handler: function (newValue) {
					this.selected = setSelected(newValue, this.options);
				}
			},
			type: {
				handler: function (newValue) {
					this.clearOptions()
					if (newValue) {
						this.getOptions()
					}
				}
			}
		},
		created() {
			this.getOptions();
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
			translateNSelected: translateNSelected,
			getOptions() {
				const config = {
					params: {
						type: this.type,
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false}),
				};
				ThesisAndProjectService.getAdvisors(config)
				                       .then((response) => {
					                       const data = response.data.data;
					                       data.map((item) => {
						                       this.options.push({
							                       value: item.user_id,
							                       text: item.name + " " + item.surname,
						                       });
					                       });
				                       })
				                       .then(() => {
					                       this.selected = setSelected(this.value, this.options);
				                       })
			},
			clearOptions() {
				this.selected = null;
				this.options = [];
			}
		}
	}
</script>
